import "./Modal.css";
import useUser from "../../hooks/useUser";

export default function Modal() {
  const { modal, handleOpenClose, caseModal } = useUser();

  modal === "" || caseModal === "flex"
    ? (document.documentElement.style.overflow = "hidden")
    : (document.documentElement.style.overflow = "auto");
  // IE
  modal === "hidden" || caseModal === "none"
    ? (document.body.scroll = "no")
    : (document.body.scroll = "yes");

  return (
    <nav onClick={handleOpenClose} className={`btns-menu-mobile ${modal}`}>
      <ul>
        <li>
          <button>
            <a href="#root">Inicio</a>
          </button>
        </li>
        <li>
          <button>
            <a href="#aboutMe">Quem sou</a>
          </button>
        </li>
        <li>
          <button>
            <a href="#cases">Cases</a>
          </button>
        </li>
      </ul>
    </nav>
  );
}

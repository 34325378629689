import "./footer.css";
export default function Footer() {
  return (
    <footer className="padding">
      <p>
        &copy; Copyright 2021 - Todos os direitos reservados a Lucas Fernandes
      </p>
      <p>Design and developed by Lucas Fernandes</p>
    </footer>
  );
}

/* eslint-disable no-lone-blocks */
import "./carousel.css";

import ImageSlider from "./ImageSlider.js";

export default function Carousel() {
  return (
    <div className="div-carousel">
      <ImageSlider className="image-slider" />;
    </div>
  );
}

/* eslint-disable jsx-a11y/alt-text */
import "./about-me.css";
import me from "../../assets/Polish_20211009_150543993.jpg";
import whatsapp from "../../assets/whatsapp.svg";
import github from "../../assets/github.svg";
import envelope from "../../assets/envelope.svg";
import linkedin from "../../assets/linkedin.svg";

export default function AboutMe() {
  return (
    <div className="aboutMe">
      <div className="div-picture">
        <p className="myName"> &lt; Lucas Fernandes /&gt;</p>
        <img className="my-picture" src={me} alt="Foto Lucas" />
      </div>
      <div className="apresentation">
        <h1> Quem sou</h1>
        <h3>Lucas Fernandes Paixão dos Santos</h3>
        <p>
          Sou um desenvolvedor web e trabalho como engenheiro de software
          no Ifood, a maior foodtech da América Latina. Gosto de escrever um código acessível, e fácil de
          entender, usando práticas modernas de Java, Node, ES6, TS, React, e PostgreSQL.
        </p>
        <div className="socialMedias">
          <div></div>

          <a
            target="_blank"
            rel="noreferrer"
            href="https://api.whatsapp.com/send?phone=5582994012061"
            className="buttonOne"
          >
            <img src={whatsapp} alt="Whatapp" />
          </a>

          <a
            target="_blank"
            rel="noreferrer"
            href="https://github.com/lucasfpds/"
            className="buttonTwo"
          >
            <img src={github} alt="Git hub" />
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="mailto:lucas@lucasfernandesdev.com.br?subject=Me envie um orçamento??&body=Olá, gostaria de um orçamento para desenvolver um site. Meu nome é..."
            className="buttonThree"
          >
            <img src={envelope} alt="Email" />
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.linkedin.com/in/lfpds/"
            className="buttonFour"
          >
            <img src={linkedin} alt="Linkedin" />
          </a>
        </div>
      </div>
    </div>
  );
}

import "./introduction.css";

export default function Introduction(props) {
  return (
    <div className="introductionText padding">
      <span className="pixelPerfect ">PIXEL PERFECT </span>
      <h1 className="h1">web developer</h1>
      <h4>Olá, me chamo Lucas Fernandes e eu amo construir aplicações web.</h4>
    </div>
  );
}

import "./header.css";
import cifrao from "../../assets/cifrao.svg";
import useUser from "../../hooks/useUser";

function getBrowserInfo() {
  const isOpera = !!window.opera || navigator.userAgent.indexOf(" OPR/") >= 0;

  const isFirefox = typeof InstallTrigger !== "undefined"; // Firefox 1.0+
  const isSafari =
    Object.prototype.toString.call(window.HTMLElement).indexOf("Constructor") >
    0;

  const isChrome = !!window.chrome && !isOpera; // Chrome 1+
  const isIE = /*@cc_on!@*/ false || !!document.documentMode; // At least IE6
  if (isOpera) {
    return "opera";
  } else if (isFirefox) {
    return "firefox";
  } else if (isChrome) {
    return "chrome";
  } else if (isSafari) {
    return "safari";
  } else if (isIE) {
    return "ie";
  } else {
    return 0;
  }
}

export default function Header() {
  const { img, handleOpenClose } = useUser();

  // fix bug for firefox a::before
  getBrowserInfo() === "firefox" &&
    (document.styleSheets[3].cssRules[123].style.display = "none");

  return (
    <header>
      <nav className="padding nav-principal">
        <img
          className="mobile"
          onClick={handleOpenClose}
          src={img}
          alt="Abrir e Fechar Menu"
        />
        <ul>
          <li className="logoLi">
            <p className="logo">
              &lt;<a href="#root">Lucas</a>/&gt;
            </p>
          </li>
          <li>
            <button>
              <a href="#aboutMe">Quem sou</a>
            </button>
            <button>
              <a href="#cases">Cases</a>
            </button>
          </li>
        </ul>
        <a
          target="_blank"
          rel="noreferrer"
          href="mailto:lucas@lucasfernandesdev.com.br?subject=Me envie um orçamento??&body=Olá, gostaria de um orçamento para desenvolver um site. Meu nome é..."
          className="mobile"
        >
          <img src={cifrao} alt="orçamento por email" />
        </a>
      </nav>
    </header>
  );
}

import "./App.css";
import Header from "./components/header/header";
import Introduction from "./components/introduction/introduction";
import AboutMe from "./components/about-me/about-me";
import Stacks from "./components/stacks/stacks";
import Cases from "./components/cases/cases";
import Footer from "./components/footer/footer";
import { UserProvider } from "./context/UserContext";
import Modal from "./components/Modal/Modal";
import ModalCase from "./components/Modal/ModalCase";

function App() {
  return (
    <UserProvider>
      <div className="App">
        <section className="header ">
          <Header />
          <Modal />
        </section>

        <main>
          <section className="section_introduction ">
            <Introduction />
            <canvas id="canvas1"></canvas>
          </section>

          <section id="aboutMe" className="section_about_me padding anime">
            <AboutMe />
          </section>

          <section className="section_stacks">
            <Stacks />
            <canvas className="canvas"></canvas>
          </section>

          <section className="section_cases padding">
            <Cases />
          </section>
        </main>
        <section>
          <Footer />
        </section>
        <section>
          <ModalCase />
          <Modal />
        </section>
      </div>
    </UserProvider>
  );
}

export default App;

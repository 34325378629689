/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState } from "react";
import useUser from "../../hooks/useUser.js";
import arrowSlide from "../../assets/arrow-slide.svg";

const ImageSlider = () => {
  const [active, setActive] = useState("active");
  const { SliderData, current, setCurrent, setCaseModal } =
    useUser();
  const length = SliderData.length;

  function nextItem() {
    setActive("");
    setTimeout(() => {
      setActive("slide-in-left-carousel");
    }, 100);
  }
  const handleIndicator = (index) => {
    setCurrent(index);
    nextItem();
  };

  const nextSlide = () => {
    setCurrent(current === length - 1 ? 0 : current + 1);
    nextItem();
  };

  const prevSlide = (e) => {
    setCurrent(current === 0 ? length - 1 : current - 1);
    setActive("");
    setTimeout(() => {
      setActive("slide-in-right-carousel");
    }, 100);
  };

  
  function handleOpenCaseModal() {
    document.querySelector("header").style.display = "none";
    console.log(document.documentElement.style.overflow);
    setCaseModal("flex");
  }

  return (
    <>
      <div>
        <div className="slider">
          <button className="left-arrow" onClick={(e) => prevSlide(e)}>
            &lt;&lt;
          </button>

          <div className={`slide ${active}`} id={current}>
            <img
              className="carousel-image"
              src={SliderData[current].image}
              alt={SliderData[current].alt}
            />
            <div className="text-carousel">
              <h2>{SliderData[current].title}</h2>
              <h4>{SliderData[current].stack}</h4>
              <p>{SliderData[current].text}</p>
              <div>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={SliderData[current].link}
                >
                  Acesse
                </a>
                {SliderData[current].repo && (
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={SliderData[current].repo}
                  >
                    github
                  </a>
                )}
              </div>
              <button onClick={handleOpenCaseModal} className="btn-case">
                <img src={arrowSlide} alt="Acessar Case" />
              </button>
            </div>
          </div>

          <button className="right-arrow" onClick={nextSlide}>
            &gt;&gt;
          </button>
        </div>
        <div id="cases" className="carousel-indicator">
          {SliderData.map((e, index) => {
            return (
              <p
                onClick={() => handleIndicator(index)}
                key={index}
                id={index}
                className={index === current ? "indicator-active" : ""}
              >
                ⚪
              </p>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default ImageSlider;

import useUser from "../../hooks/useUser.js";
import close from "../../assets/close.svg";

export default function ModalCase() {
  const { SliderData, current, caseModal, setCaseModal } = useUser();

  function handleCloseCaseModal() {
    document.querySelector("header").style.display = "flex";
    setCaseModal("none");
  }

  return (
    <div className="modal-case" style={{ display: caseModal }}>
      <img
        onClick={handleCloseCaseModal}
        className="btn-close"
        src={close}
        alt="Fechar Modal"
      />
      <div>
        <h2>{SliderData[current].title}</h2>
        <img src={SliderData[current].image} alt={SliderData[current].alt} />
        <div className="text-carousel">
          <h4>{SliderData[current].stack}</h4>
          <p>{SliderData[current].text}</p>
          <div
            onClick={handleCloseCaseModal}
            style={{ justifyContent: !SliderData[current].repo && "center" }}
          >
            <a target="_blank" rel="noreferrer" href={SliderData[current].link}>
              Acesse
            </a>
            {SliderData[current].repo && (
              <a
                target="_blank"
                rel="noreferrer"
                href={SliderData[current].repo}
              >
                github
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

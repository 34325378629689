import { useState } from "react";
import hamburguer from "../assets/hamburguer.svg";
import close from "../assets/close.svg";

import illumine from "../assets/illumine.jpg";
import orcamentoPessoal from "../assets/orcamento_pessoal.png";
import appCalculadora from "../assets/app_calculadora.png";
import chaleHotel from "../assets/chale_hotel.jpeg";
import cubosFlix from "../assets/cubos_flix.jpeg";
import finans from "../assets/finans.jpeg";
import gameMataMosquito from "../assets/game_mata_mosquito.jpeg";
import museuNacional from "../assets/museu_nacional.jpeg";
import spotify from "../assets/spotify.jpeg";
import dindin from "../assets/dindin.jpg";
import apiLoja from "../assets/api_loja.jpg";
import apiBanco from "../assets/api_banco.jpg";
import kontacts from "../assets/kontacts.jpg";
import apiUsuarios from "../assets/api_usuarios.jpg";
import gelarmaq from "../assets/gelarmaq.png";
import qualTributacao from "../assets/qual_tributacao.png";

import illumine_mobile from "../assets/Mobile/illumine_mobile.png";
import orcamentoPessoal_mobile from "../assets/Mobile/orcamento_pessoal_mobile.png";
import appCalculadora_mobile from "../assets/Mobile/app_calculadora_mobile.png";
import chaleHotel_mobile from "../assets/Mobile/chale_hotel_mobile.png";
import cubosFlix_mobile from "../assets/Mobile/cubos_flix_mobile.jpg";
import finans_mobile from "../assets/Mobile/finans_mobile.png";
import gameMataMosquito_mobile from "../assets/Mobile/game_mata_mosquito_mobile.png";
import museuNacional_mobile from "../assets/Mobile/museu_nacional_mobile.png";
import spotify_mobile from "../assets/Mobile/spotify_mobile.png";
import dindin_mobile from "../assets/Mobile/dindin_mobile.jpg";
import apiLoja_mobile from "../assets/Mobile/api_loja_mobile.png";
import apiBanco_mobile from "../assets/Mobile/api_banco_mobile.png";
import kontacts_mobile from "../assets/Mobile/kontacts_mobile.png";
import apiUsuarios_mobile from "../assets/Mobile/api_usuarios_mobile.png";
import gelarmaq_mobile from "../assets/Mobile/gelarmaq_mobile.png";
import qualTributacao_mobile from "../assets/Mobile/qual_tributacao_mobile.png";

export default function useUserProvider() {
  const [modal, setModal] = useState("hidden");
  const [img, setImg] = useState(hamburguer);
  const [current, setCurrent] = useState(0);
  const [caseModal, setCaseModal] = useState('none');

  function handleOpenClose() {
    modal === "" ? setModal("hidden") : setModal("");
    modal === "" ? setImg(hamburguer) : setImg(close);
  }

  const [width, setWidth] = useState(window.innerWidth);

  const imgsDesktop = {
    illumine: width < 768 ? illumine_mobile : illumine,
    orcamentoPessoal: width < 768 ? orcamentoPessoal_mobile : orcamentoPessoal,
    appCalculadora: width < 768 ? appCalculadora_mobile : appCalculadora,
    chaleHotel: width < 768 ? chaleHotel_mobile : chaleHotel,
    cubosFlix: width < 768 ? cubosFlix_mobile : cubosFlix,
    finans: width < 768 ? finans_mobile : finans,
    gameMataMosquito: width < 768 ? gameMataMosquito_mobile : gameMataMosquito,
    museuNacional: width < 768 ? museuNacional_mobile : museuNacional,
    spotify: width < 768 ? spotify_mobile : spotify,
    dindin: width < 768 ? dindin_mobile : dindin,
    apiLoja: width < 768 ? apiLoja_mobile : apiLoja,
    apiBanco: width < 768 ? apiBanco_mobile : apiBanco,
    kontacts: width < 768 ? kontacts_mobile : kontacts,
    apiUsuarios: width < 768 ? apiUsuarios_mobile : apiUsuarios,
    gelarmaq: width < 768 ? gelarmaq_mobile : gelarmaq,
    qualTributacao: width < 768 ? qualTributacao_mobile : qualTributacao,
  };

  window.addEventListener("resize", function () {
    setWidth(window.innerWidth);
  });

  const SliderData = [
    {
      image: imgsDesktop.qualTributacao,
      title: "Qual Tributação",
      stack: "Html | Css | Typescript | React | Axios | Styled Components",
      text: "Site responsivo construido em React e Typescript para comparação de tributações a partir do faturamento anual da empresa.",
      link: "https://qual-tributacao.vercel.app/",
      alt: "Site Qual Tributação",
    },
    {
      image: imgsDesktop.gelarmaq,
      title: "GelarmaqBH",
      stack: "Html | Css | JavaScript | React",
      text: "Site responsivo construido em react para cliente no segmento de assistência técnica de geladeiras e máquinas.",
      link: "https://site-refrigeracao-assistencia.vercel.app/",
      alt: "Site gelarmaq",
    },
    {
      image: imgsDesktop.apiUsuarios,
      title: "Api Usuarios",
      stack:
        "JavaScript | NodeJS | Express | JWT | BCRYPT | PostgreSQL | Knex | Yup",
      text: "REST API onde é possível obter e fazer alterações em um banco de dados relacional utilizando token, encriptação de senha, e auxílio do knex e yup para um clean code ainda melhor.",
      link: "https://api-usuarios-beta.herokuapp.com/docs/",
      repo: "https://github.com/lucasfpds/api-usuarios",
      alt: "Site Api Usuarios",
    },
    {
      image: imgsDesktop.kontacts,
      title: "KONTACTS",
      stack: "Html | Css | JavaScript | React",
      text: "Single page em React utilizando hooks personalizados e próprios do React, Bibliotecas, requisições assíncronas com uso de token, API de contexto, roteamento de páginas, componentes estilizados e responsividade.",
      link: "https://kontacts-red.vercel.app/",
      repo: "https://github.com/lucasfpds/kontacts",
      alt: "Site app kontacts",
    },
    {
      image: imgsDesktop.dindin,
      title: "DinDin",
      stack: "Html | Css | React | JavaScript | NodeJS",
      text: "Aplicação para consumo de API no back-end fazendo leitura, criação, atualização e apagando dados em um banco de dados, sempre tratando todos os dados durante esses processos. A aplicação usa vários conceitos do React, todos os componentes e funcionalidades são construídos do zero.",
      link: "https://app-din-din-fe.vercel.app/",
      repo: "https://github.com/lucasfpds/App_Din_Din_FE",
      alt: "Site aplicativo Dindin",
    },
    {
      image: imgsDesktop.apiLoja,
      title: "API Mercado",
      stack: "JavaScript | NodeJS | Express | JWT | BCRYPT | PostgreSQL",
      text: "O projeto visa a construção de uma REST API onde seria possível cadastrar usuários para uma loja, e que esses usuários pudessem fazer login para cadastrar, visualizar, editar e excluir produtos em um banco de dados relacional.",
      link: "https://apimarketcubos.herokuapp.com/docs/",
      repo: "https://github.com/lucasfpds/api-mercado",
      alt: "API Mercado",
    },
    {
      image: imgsDesktop.cubosFlix,
      title: "Cubos Flix",
      stack: "Html | Css | JavaScript",
      text: "Aplicação para um serviço de streaming. Possui ferramentas para ver os lançamentos mais recentes, pesquisa de filmes, sinopses e trailers. O conteúdo deste projeto abordou a propagação de eventos e alteração de propriedades com eventos através do DOM, formulários, requisições em consumo de API de forma assíncrona e, o HTML e CSS dinâmico.",
      link: "https://cubos-flix.vercel.app/",
      repo: "https://github.com/lucasfpds/cubos-flix",
      alt: "Site Cubos Flix",
    },
    {
      image: imgsDesktop.apiBanco,
      title: "API Banco",
      stack: "JavaScript | NodeJS | Express",
      text: "API REST utilizando configuração de rotas, controladores, framework e módulos para o NodeJS. Tudo com uso de programação assíncrona, integração de api externa e codificação JSON e Swagger.",
      link: "https://apicubosbank.herokuapp.com/docs/",
      repo: "https://github.com/lucasfpds/api-bank",
      alt: "API Banco",
    },
    {
      image: imgsDesktop.spotify,
      title: "Clone Spotify",
      stack: "Html | Css | JavaScript | Bootstrap",
      text: "Utilizando Bootstrap fiz um espelho da antiga página do Spotify com responsividade, com auxílio do boostrap fiz o uso além da responsividade o  carrossel e colapso de botões.",
      link: "https://clone-spotify-alpha.vercel.app/",
      repo: "https://github.com/lucasfpds/clone-spotify",
      alt: "Site Clone Spotify",
    },
    {
      image: imgsDesktop.finans,
      title: "Finans",
      stack: "Html | Css | JavaScript | Bootstrap",
      text: "Neste Projeto a construção visava fazer uma single page aplicando responsividade utilizando media queries e mais algumas facilidades do CSS e também do Bootstrap.",
      link: "https://finans-jade.vercel.app/",
      repo: "https://github.com/lucasfpds/finans",
      alt: "Site Finans",
    },
    {
      image: imgsDesktop.appCalculadora,
      title: "App Calculadora",
      stack: "Html | Css | JavaScript | Bootstrap",
      text: "Esta foi uma simples aplicação de uma calculadora usando técnicas simples de JS e DOM no front combinado ao uso da lógica de programação.",
      link: "https://app-calculadora.vercel.app/",
      repo: "https://github.com/lucasfpds/app-calculadora",
      alt: "Site App Calculadora",
    },
    {
      image: imgsDesktop.gameMataMosquito,
      title: "Game Mata Mosquito",
      stack: "Html | Css | JavaScript | Bootstrap",
      text: "Aplicação web de um mini jogo usando o JS, HTML, DOM e o CSS com um código bem limpo e muito funcional com responsividade.",
      link: "https://game-mata-mosquito-jade.vercel.app/",
      repo: "https://github.com/lucasfpds/Game_Mata_Mosquito",
      alt: "Site Game Mata Mosquito",
    },
    {
      image: imgsDesktop.orcamentoPessoal,
      title: "Orçamento Pessoal",
      stack: "Html | Css | JavaScript | Bootstrap",
      text: "Aplicação web com a finalidade de ser um controlador de despesas pessoais podendo criar e deletar despesas cadastradas. Foi utilizado JS, ES6, POO, HTML e CSS.",
      link: "https://app-orcamento-pessoal-three.vercel.app/",
      repo: "https://github.com/lucasfpds/app-orcamento-pessoal",
      alt: "Site Orçamento Pessoal",
    },
    {
      image: imgsDesktop.museuNacional,
      title: "Museu Nacional",
      stack: "Html | Css | JavaScript | Bootstrap",
      text: "Single page de um layout com estilização de tabelas e formulários, marcação de página, e layout líquido com CSS e HTML.",
      link: "https://museu-nacional-six.vercel.app/",
      repo: "https://github.com/lucasfpds/museu_nacional",
      alt: "Site Museu Nacional",
    },
    {
      image: imgsDesktop.chaleHotel,
      title: "Chalé Hotel",
      stack: "Html | Css | JavaScript | Bootstrap",
      text: "Single page com um layout fluido. Usei estilização de tabelas e formulários, marcação de página, e o layout líquido com CSS e HTML.",
      link: "https://chale-hotel.vercel.app/",
      repo: "https://github.com/lucasfpds/chale_hotel",
      alt: "Site Chalé Hotel",
    },
  ];

  return {
    modal,
    setModal,
    img,
    setImg,
    width,
    SliderData,
    handleOpenClose,
    current,
    setCurrent,
    caseModal,
    setCaseModal,
  };
}

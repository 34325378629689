import "./stacks.css";
import sass from "../../assets/sass-original.svg";
import bootstrap from "../../assets/bootstrap-plain-wordmark.svg";
import typescript from "../../assets/typescript-original.svg";
import jquery from "../../assets/jquery-original.svg";
import mysql from "../../assets/mysql-original-wordmark.svg";
import postgresql from "../../assets/postgresql-original.svg";
import java from "../../assets/java.svg";
import nodejs from "../../assets/nodejs-original.svg";

export default function Stacks() {
  return (
    <div className="padding">
      <div className="div-stacks ">
        <img
          src="https://raw.githubusercontent.com/devicons/devicon/master/icons/html5/html5-original.svg"
          alt="html"
        />
        <img
          src="https://raw.githubusercontent.com/devicons/devicon/master/icons/css3/css3-original.svg"
          alt="icone css3"
        />
        <img src={sass} alt="icone sass" />
        <img src={bootstrap} alt="icone bootstrap" />
        <img src={java} alt="icone java" />
        <img
          src="https://raw.githubusercontent.com/devicons/devicon/master/icons/javascript/javascript-plain.svg"
          alt="icone javascript"
        />
        <img src={typescript} alt="icone typescript" />
        <img
          src="https://raw.githubusercontent.com/devicons/devicon/master/icons/react/react-original.svg"
          alt="icone react"
        />
        <img src={jquery} alt="icone jquery" />
        <img src={mysql} alt="icone mysql" />
        <img src={postgresql} alt="icone postgresql" />
        <img src={nodejs} alt="icone nodejs" />
      </div>
    </div>
  );
}
